<template>
  <pull-to :top-load-method="onRefresh" :top-config="TOP_DEFAULT_CONFIG">
    <div>
      <div class="text-center">
        <h3 class="pt-4"><i class="fa fa-wallet"></i> Retiradas (Vales)</h3>
      </div>
      <div class="row">
        <div
          class="col-12 pl-5 pr-5 pt-2 pb-1 text-left linhaFina mb-1 pedido text-white"
          v-for="ret in dados"
          :key="ret.cod_saida"
        >
          <h4>
            # {{ ret.cod_saida }}
            <span class="float-right" style="font-size: 13px"
              >{{ ret.data | moment("HH:mm:ss") }}
            </span>
          </h4>

          {{ ret.motivo }}
          <span class="float-right"> R$ {{ ret.valor | currency }} </span>
          <div class="text-right mt-3">
            <button class="btn btn-warning mr-1" @click="edit(ret)">
              <i class="fa fa-edit"></i> Editar
            </button>
            <button class="btn btn-danger" @click="excluir(ret)">
              <i class="fa fa-times"></i> Excluir
            </button>
          </div>
        </div>
        <div class="col-12" style="height: 150px"></div>
      </div>

      <div
        style="
          position: fixed;
          bottom: 60px;
          width: 95%;
          left: 0px;
          height: 60px;
          text-align: right p-5;
        "
      >
        <button
          class="btn btn-secondary btn-circle"
          style="border-radius: 100%; width: 60px; height: 60px"
          @click="novo()"
        >
          <i class="fa fa-plus" style="font-size: 30px"></i>
        </button>
      </div>
    </div>
    <b-modal id="modal-form" title="Adicionar / Editar" @ok="salvar">
      <b-form-group label="Valor" label-for="input-valor">
        <b-input
          id="input-valor"
          type="text"
          v-money="moneyMask"
          class="text-center"
          v-model="valor"
          placeholder="R$ 0,00"
          ref="txtVl"
          pattern="\d*"
        >
        </b-input>
      </b-form-group>
      <b-form-group label="Motivo" label-for="input-motivo">
        <b-form-textarea
          id="input-motivo"
          v-model="motivo"
          placeholder="Descreva o motivo da saída"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </b-form-group>
    </b-modal>
  </pull-to>
</template>
<script>
import PullTo from "vue-pull-to";
import axios from "@/utils/axios.js";

export default {
  components: {
    PullTo,
  },
  data() {
    return {
      dados: [],
      TOP_DEFAULT_CONFIG: {
        pullText: "arraste e solte para atualizar", // The text is displayed when you pull down
        triggerText: "arraste e solte para atualizar", // The text that appears when the trigger distance is pulled down
        loadingText: "atualizando...", // The text in the load
        doneText: "atualizado!", // Load the finished text
        failText: "f", // Load failed text
        loadedStayTime: 400, // Time to stay after loading ms
        stayDistance: 50, // Trigger the distance after the refresh
        triggerDistance: 70, // Pull down the trigger to trigger the distance
      },
      motivo: "",
      valor: null,
      cod_saida: null,
    };
  },
  methods: {
    async carregar() {
      const result = await axios.post("retiradas",{data: this.$store.state.data.format("YYYY-MM-DD")});
      if (result) {
        this.dados = result.data;
      }
    },
    async onRefresh(loaded) {
      await this.carregar();
      loaded("done");
    },
    novo() {
      this.motivo = "";
      this.valor = null;
      this.cod_saida = null;
      this.$bvModal.show("modal-form");
      console.log(this.$refs.txtVl);
      this.$refs.txtVl.focus();
    },
    edit(ret) {
      if (ret) {
        this.cod_saida = ret.cod_saida;
        this.valor = ret.valor;
        this.motivo = ret.motivo;
        this.$bvModal.show("modal-form");
      }
    },
    async excluir(ret) {
      if (ret) {
        await this.$swal
          .fire({
            title: "Você tem certeza que deseja excluir?",
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              const res = await axios.post("retiradas/excluir", {
                cod_saida: ret.cod_saida,
              });
              if (res && res.data && res.data.success) {
                this.$swal.fire({
                  toast: true,
                  position: "top",
                  showConfirmButton: false,
                  timer: 1000,
                  timerProgressBar: true,
                  title: "Excluído",
                  icon: "success",
                });
              }
              this.carregar();
            }
          });
      }
    },
    async salvar(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (!this.valor || this.valor <= 0) {
        this.$swal.fire({
          title: "Atenção",
          text: "Informe um valor válido",
          icon: "error",
        });
        return false;
      }
      if (this.motivo.trim() == "" || this.motivo.length <= 0) {
        this.$swal.fire({
          title: "Atenção",
          text: "Informe um motivo válido",
          icon: "error",
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        return false;
      }

      const result = await axios.post("retiradas/salvar", {
        cod_saida: this.cod_saida,
        motivo: this.motivo,
        valor: this.valor,
        data: this.$store.state.data.format("YYYY-MM-DD")
      });

      if (result && result.data && result.data.success) {
        this.$swal.fire({
          title: "Retirada salva com sucesso!",
          icon: "success",
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      }

      this.$bvModal.hide("modal-form");
      this.carregar();
    },
  },
  mounted() {
    this.carregar();
  },
};
</script>